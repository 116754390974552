#__toastmynuts__wrapper,
#__toastmynuts__wrapper * {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}

#__toastmynuts__wrapper {
	--_gap: 16px;

	position: fixed;

	width: 95%;
	height: 95%;

	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	margin: auto;

	pointer-events: none;
	touch-action: none;

	z-index: 999;
}

#__toastmynuts__ {
	pointer-events: all;
	touch-action: auto;

	padding: 0;

	list-style: none;
}

#__toastmynuts__[data-position-x="left"] .__toastmynuts__toast {
	left: 0;
}

#__toastmynuts__[data-position-x="right"] .__toastmynuts__toast {
	right: 0;
}

#__toastmynuts__[data-position-x="right"] .__toastmynuts__toast[dir="rtl"] {
	left: 0;
	right: auto;
}

#__toastmynuts__[data-position-x="left"] .__toastmynuts__toast[dir="rtl"] {
	right: 0;
	left: auto;
}

#__toastmynuts__[data-position-x="right"] .__toastmynuts__toast[dir="ltr"] {
	right: 0;
	left: auto;
}

#__toastmynuts__[data-position-x="left"] .__toastmynuts__toast[dir="ltr"] {
	left: 0;
	right: auto;
}

#__toastmynuts__[data-position-x="middle"] .__toastmynuts__toast {
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

#__toastmynuts__[data-position-y="top"] .__toastmynuts__toast {
	top: 0;
	--_dir_y: 1;
}

#__toastmynuts__[data-position-y="bottom"] .__toastmynuts__toast {
	bottom: 0;
	--_dir_y: -1;
}

#__toastmynuts__ .__toastmynuts__toast {
	position: absolute;

	width: -moz-min-content;

	width: min-content;

	will-change: transform, opacity, visibility;
	transition-property: transform, opacity, visibility;

	transform: translatex(0) translatey(0) scale(1) rotate(0deg);

	transform: translatex(var(--_x, 0)) translatey(var(--_y, 0)) scale(var(--_scale, 1)) rotate(var(--_rotate, 0deg));

	z-index: var(--_z-idx);
}

:where(#__toastmynuts__ .__toastmynuts__toast) {
	--_gap-height: calc(var(--_idx) * var(--_gap, 0px));
	--_lift: calc(var(--_gap-height) * var(--_dir_y));
	--_offset: calc(var(--_lift) + var(--_height-offset, 0px) * var(--_dir_y));
	--_y: var(--_offset);
}

#__toastmynuts__ .__toastmynuts__toast > div {
	width: min(80vw, 20rem);
	width: var(--_toastmynuts-tw, min(80vw, 20rem));
	height: auto;
	height: var(--_initial-height, auto);

	display: flex;

	position: relative;

	will-change: height, background-color, color, box-shadow;
	transition-property: height, background-color, color, box-shadow;

	color: var(--_toastmynuts-fg, var(--_toastmynuts-default-fg));
	background-color: var(--_toastmynuts-bg, var(--_toastmynuts-default-bg));

	box-shadow:
		0px 1px 1px hsla(0, 0%, 0%, 0.1),
		0px 2px 2px hsla(0, 0%, 0%, 0.1),
		0px 4px 4px hsla(0, 0%, 0%, 0.1),
		0px 8px 8px hsla(0, 0%, 0%, 0.1),
		0px 16px 16px hsla(0, 0%, 0%, 0.1);

	box-shadow:
		0px 1px 1px var(--_shadow-color, hsl(var(--_toastmynuts-default-sc, 0 0% 0%) / 0.1)),
		0px 2px 2px var(--_shadow-color, hsl(var(--_toastmynuts-default-sc, 0 0% 0%) / 0.1)),
		0px 4px 4px var(--_shadow-color, hsl(var(--_toastmynuts-default-sc, 0 0% 0%) / 0.1)),
		0px 8px 8px var(--_shadow-color, hsl(var(--_toastmynuts-default-sc, 0 0% 0%) / 0.1)),
		0px 16px 16px var(--_shadow-color, hsl(var(--_toastmynuts-default-sc, 0 0% 0%) / 0.1));

	padding: 1rem;

	word-wrap: anywhere;
	overflow: hidden;

	-webkit-user-drag: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	     user-select: none;

	outline: none;

	z-index: var(--_z-idx);
}

:where(#__toastmynuts__ .__toastmynuts__toast > div) {
	gap: 1rem;

	border-radius: 0.5rem;

	border-radius: var(--_toastmynuts-br, 0.5rem);

	font-family:
		system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif,
		blinkmacsystemfont,
		"segoe ui",
		roboto,
		oxygen,
		ubuntu,
		cantarell,
		"open sans",
		"helvetica neue",
		sans-serif;
	line-height: 1.5;
}

#__toastmynuts__ .__toastmynuts__toast > div > * {
	will-change: opacity, visibility;
	transition-property: opacity, visibility;
}

:where(#__toastmynuts__ .__toastmynuts__toast),
:where(#__toastmynuts__ .__toastmynuts__toast > div),
:where(#__toastmynuts__ .__toastmynuts__toast > div > *) {
	transition-duration: 350ms;
	transition-duration: var(--_toastmynuts-td, 350ms);
	transition-timing-function: ease;
}

/** we target the toast itself and not its content wrapper for the pseudo
element because the pointerevent listeners for the toastcontainer only recognize
the pseudo elements of a direct child. i don't know the reason for this and
can't seem to find it on the internet, but if anyone knows why, it'd be great. **/
:where(#__toastmynuts__ .__toastmynuts__toast)::after {
	position: absolute;

	content: "";

	width: 100%;
	height: calc(var(--_gap) + 1px);

	left: 0;
	bottom: 100%;
}

#__toastmynuts__ .__toastmynuts__toast > div .__toastmynuts__message {
	flex-grow: 1;
}

#__toastmynuts__ .__toastmynuts__toast > div .__toastmynuts__close-btn,
#__toastmynuts__ .__toastmynuts__toast > div .__toastmynuts__icon {
	flex-shrink: 0;
}

#__toastmynuts__ .__toastmynuts__toast > div .__toastmynuts__icon {
	align-self: center;
}

:where(#__toastmynuts__ .__toastmynuts__toast > div .__toastmynuts__icon svg) {
	transition-duration: 0s;
	transition-duration: initial;
}

#__toastmynuts__ .__toastmynuts__toast > div .__toastmynuts__icon svg,
#__toastmynuts__ .__toastmynuts__toast > div .__toastmynuts__icon,
#__toastmynuts__ .__toastmynuts__toast > div .__toastmynuts__close-btn {
	width: 1.5rem;
	height: 1.5rem;
}

#__toastmynuts__ .__toastmynuts__toast > div .__toastmynuts__close-btn {
	padding: 0.25rem;
	background-color: transparent;
	border: none;
	align-self: flex-start;
	position: relative;

	top: -0.75rem;
	right: -0.75rem;

	color: var(--_toastmynuts-fg, var(--_toastmynuts-default-fg));

	min-width: auto;
	min-height: auto;
	width: -moz-fit-content;
	width: fit-content;
	height: -moz-fit-content;
	height: fit-content;

	transform: none;
}

:where(#__toastmynuts__ .__toastmynuts__toast > div .__toastmynuts__close-btn) {
	display: inline-flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;
}

#__toastmynuts__ .__toastmynuts__toast[dir="rtl"] > div .__toastmynuts__close-btn {
	left: -0.75rem;
	right: auto;
}

#__toastmynuts__ .__toastmynuts__toast[dir="ltr"] > div .__toastmynuts__close-btn {
	right: -0.75rem;
	left: auto;
}

#__toastmynuts__ .__toastmynuts__toast > div .__toastmynuts__close-btn svg {
	width: 1rem;
	height: 1rem;
}

:where(#__toastmynuts__[data-expanded="false"][data-stackable="true"] .__toastmynuts__toast) {
	--_y: var(--_lift);
	--_scale: calc(1 - var(--_idx) * 0.05);
}

:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-state="entering"]),
:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-state="closing"]) {
	--_y: calc(var(--_offset) - (100% * var(--_dir_y)));
}

:where(
		#__toastmynuts__[data-expanded="false"][data-stackable="true"] .__toastmynuts__toast[data-toast-state="closing"]
	) {
	--_y: calc(var(--_lift) - (50% * var(--_dir_y)));
}

:where(#__toastmynuts__ .__toastmynuts__toast[data-exit-swipe="true"]) {
	--_y: calc(var(--_offset) - (100% * var(--_dir_y)) - var(--_swipe-offset, 0) * 1px);
}

#__toastmynuts__ .__toastmynuts__toast[data-exit-swipe="true"] {
	z-index: -1;
}

:where(#__toastmynuts__[data-expanded="false"][data-stackable="true"] .__toastmynuts__toast[data-exit-swipe="true"]) {
	--_y: calc(var(--_lift) - (50% * var(--_dir_y)) - var(--_swipe-offset, 0) * 1px);
}

:where(#__toastmynuts__ .__toastmynuts__toast[data-is-swiping="true"]) {
	--_y: calc(var(--_offset) - var(--_swipe-offset, 0) * 1px);
}

#__toastmynuts__ .__toastmynuts__toast[data-is-swiping="true"] {
	transition: none;
}

#__toastmynuts__[data-expanded="false"][data-stackable="true"]
	.__toastmynuts__toast:not([data-front-toast="true"])
	> div {
	height: auto;
	height: var(--_front-toast-height, auto);
}

#__toastmynuts__ .__toastmynuts__toast[data-toast-state="entering"],
#__toastmynuts__ .__toastmynuts__toast[data-toast-state="hidden"],
#__toastmynuts__ .__toastmynuts__toast[data-toast-state="closing"],
#__toastmynuts__[data-expanded="false"][data-stackable="true"]
	.__toastmynuts__toast:not([data-front-toast="true"])
	> div
	> * {
	opacity: 0;
	visibility: hidden;
}

:where(#__toastmynuts__ .__toastmynuts__toast) {
	--_toastmynuts-default-bg: #fff;
	--_toastmynuts-default-fg: #000;
	--_toastmynuts-default-sc: 0 0% 0%;
}

:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-type="success"][data-rich-colors="true"]) {
	--_toastmynuts-default-bg: #acf0be;
	--_toastmynuts-default-fg: #214f1b;
	--_toastmynuts-default-sc: 136 69% 81%;
}

:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-type="info"][data-rich-colors="true"]) {
	--_toastmynuts-default-bg: #acbff0;
	--_toastmynuts-default-fg: #1b2b4f;
	--_toastmynuts-default-sc: 222 49% 21%;
}

:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-type="error"][data-rich-colors="true"]) {
	--_toastmynuts-default-bg: #f0acac;
	--_toastmynuts-default-fg: #4f1b1b;
	--_toastmynuts-default-sc: 0 49% 21%;
}

:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-type="warning"][data-rich-colors="true"]) {
	--_toastmynuts-default-bg: #f0e6ac;
	--_toastmynuts-default-fg: #3f440f;
	--_toastmynuts-default-sc: 66 64% 16%;
}

@media (prefers-color-scheme: dark) {
	:where(#__toastmynuts__ .__toastmynuts__toast) {
		--_toastmynuts-default-bg: #121212;
		--_toastmynuts-default-fg: #fff;
		--_toastmynuts-default-sc: 0 0% 100%;
	}

	:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-type="success"][data-rich-colors="true"]) {
		--_toastmynuts-default-bg: #214f1b;
		--_toastmynuts-default-fg: #acf0be;
		--_toastmynuts-default-sc: 113 49% 21%;
	}

	:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-type="info"][data-rich-colors="true"]) {
		--_toastmynuts-default-bg: #1b2b4f;
		--_toastmynuts-default-fg: #acbff0;
		--_toastmynuts-default-sc: 223 69% 81%;
	}

	:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-type="error"][data-rich-colors="true"]) {
		--_toastmynuts-default-bg: #4f1b1b;
		--_toastmynuts-default-fg: #f0acac;
		--_toastmynuts-default-sc: 223 69% 81%;
	}

	:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-type="warning"][data-rich-colors="true"]) {
		--_toastmynuts-default-bg: #3f440f;
		--_toastmynuts-default-fg: #f0e6ac;
		--_toastmynuts-default-sc: 51 69% 81%;
	}

	#__toastmynuts__ .__toastmynuts__toast > div {
		border: 1px solid hsla(0, 0%, 0%, 0.25);
		border: 1px solid hsl(var(--_toastmynuts-default-sc, 0 0% 0%) / 0.25);
	}
}

#__toastmynuts__[data-theme="light"] .__toastmynuts__toast > div,
#__toastmynuts__ .__toastmynuts__toast[data-theme="light"] > div {
	border: none;
}

:where(#__toastmynuts__[data-theme="light"] .__toastmynuts__toast),
:where(#__toastmynuts__ .__toastmynuts__toast[data-theme="light"]) {
	--_toastmynuts-default-bg: #fff;
	--_toastmynuts-default-fg: #000;
	--_toastmynuts-default-sc: 0 0% 0%;
}

:where(#__toastmynuts__[data-theme="dark"] .__toastmynuts__toast),
:where(#__toastmynuts__ .__toastmynuts__toast[data-theme="dark"]) {
	--_toastmynuts-default-bg: #121212;
	--_toastmynuts-default-fg: #fff;
	--_toastmynuts-default-sc: 0 0% 100%;
}

:where(#__toastmynuts__[data-theme="light"] .__toastmynuts__toast[data-toast-type="success"][data-rich-colors="true"]),
:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-type="success"][data-rich-colors="true"][data-theme="light"]) {
	--_toastmynuts-default-bg: #acf0be;
	--_toastmynuts-default-fg: #214f1b;
	--_toastmynuts-default-sc: 113 49% 21%;
}

:where(#__toastmynuts__[data-theme="dark"] .__toastmynuts__toast[data-toast-type="success"][data-rich-colors="true"]),
:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-type="success"][data-rich-colors="true"][data-theme="dark"]) {
	--_toastmynuts-default-bg: #214f1b;
	--_toastmynuts-default-fg: #acf0be;
	--_toastmynuts-default-sc: 136 69% 81%;
}

:where(#__toastmynuts__[data-theme="light"] .__toastmynuts__toast[data-toast-type="info"][data-rich-colors="true"]),
:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-type="info"][data-rich-colors="true"][data-theme="light"]) {
	--_toastmynuts-default-bg: #acbff0;
	--_toastmynuts-default-fg: #1b2b4f;
	--_toastmynuts-default-sc: 222 49% 21%;
}

:where(#__toastmynuts__[data-theme="dark"] .__toastmynuts__toast[data-toast-type="info"][data-rich-colors="true"]),
:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-type="info"][data-rich-colors="true"][data-theme="dark"]) {
	--_toastmynuts-default-bg: #1b2b4f;
	--_toastmynuts-default-fg: #acbff0;
	--_toastmynuts-default-sc: 223 69% 81%;
}

:where(#__toastmynuts__[data-theme="light"] .__toastmynuts__toast[data-toast-type="error"][data-rich-colors="true"]),
:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-type="error"][data-rich-colors="true"][data-theme="light"]) {
	--_toastmynuts-default-bg: #f0acac;
	--_toastmynuts-default-fg: #4f1b1b;
	--_toastmynuts-default-sc: 0 49% 21%;
}

:where(#__toastmynuts__[data-theme="dark"] .__toastmynuts__toast[data-toast-type="error"][data-rich-colors="true"]),
:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-type="error"][data-rich-colors="true"][data-theme="dark"]) {
	--_toastmynuts-default-bg: #4f1b1b;
	--_toastmynuts-default-fg: #f0acac;
	--_toastmynuts-default-sc: 223 69% 81%;
}

:where(#__toastmynuts__[data-theme="light"] .__toastmynuts__toast[data-toast-type="warning"][data-rich-colors="true"]),
:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-type="warning"][data-rich-colors="true"][data-theme="light"]) {
	--_toastmynuts-default-bg: #f0e6ac;
	--_toastmynuts-default-fg: #3f440f;
	--_toastmynuts-default-sc: 66 64% 16%;
}

:where(#__toastmynuts__[data-theme="dark"] .__toastmynuts__toast[data-toast-type="warning"][data-rich-colors="true"]),
:where(#__toastmynuts__ .__toastmynuts__toast[data-toast-type="warning"][data-rich-colors="true"][data-theme="dark"]) {
	--_toastmynuts-default-bg: #3f440f;
	--_toastmynuts-default-fg: #f0e6ac;
	--_toastmynuts-default-sc: 51 69% 81%;
}

#__toastmynuts__[data-theme="dark"] .__toastmynuts__toast > div,
#__toastmynuts__ .__toastmynuts__toast[data-theme="dark"] > div {
	border: 1px solid hsla(0, 0%, 0%, 0.275);
	border: 1px solid hsl(var(--_toastmynuts-default-sc, 0 0% 0%) / 0.275);
}

@media (prefers-reduced-motion: reduce) {
	:where(#__toastmynuts__ .__toastmynuts__toast),
	:where(#__toastmynuts__ .__toastmynuts__toast *) {
		transition-duration: 0.01ms;
		transition-timing-function: linear;
	}
}
